import React from "react";
import img from "../../../assets/ashish4.jpg";

function PhotoRound() {
  return (
    <>
      <img className="img-round" src={img} alt="" />
    </>
  );
}

export default PhotoRound;
